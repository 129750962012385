module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AttivoLifestyle","short_name":"AttivoLifestyle","start_url":"/","background_color":"#fff","theme_color":"#001B71","display":"standalone","icon":"static/logos/attivo-logo-mobile.svg"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
